import TournamentStatusType from '@/constants/TournamentStatusType'
import { utcToTimezone } from '@/common/date-utils'
import intervalToDuration from 'date-fns/intervalToDuration'
import store from '@/store'
import { isAfter, isBefore } from 'date-fns'
import { MemberRoleType } from '@/constants/MemberRoleType'
import TournamentParticipantType from '@/constants/TournamentParticipantType'
import TournamentType from '@/constants/TournamentType'

/*
 * Check if users team is part of tournament - user is team leader or owner
 */
export const listMyTournamentParticipants = (tournament, ignoreUnconfirmedRegistrations = false) => {
  if (!tournament) {
    return false
  }

  const me = store.getters['user/getMe']
  if (!me) {
    return false
  }

  // check teams
  if (tournament.participant_type_id === TournamentParticipantType.team) {
    let participatingTeam = []

    if (tournament.enable_registration) {
      const registrations = tournament.registrations.filter(
        registration => {
          if (registration.is_confirmed === false) return false

          if (ignoreUnconfirmedRegistrations === true && registration.is_confirmed === null) return false

          return me?.teams.some(
            team => {
              if (registration.participant.id !== team.id) return false

              return team.members.some(
                member => member.user.id === me.id && (member.role.id === MemberRoleType.TEAM_LEADER || member.role.id === MemberRoleType.OWNER),
              )
            },
          )
        },
      )

      participatingTeam = registrations.map(r => r.participant)
    } else {
      const tournamentParticipants = tournament.participants.filter(
        participant => me?.teams.some(team => {
          if (participant.participant?.id !== team.id) return false

          return team.members.some(
            member => member.user.id === me.id && (member.role.id === MemberRoleType.TEAM_LEADER || member.role.id === MemberRoleType.OWNER),
          )
        }),
      )

      participatingTeam = tournamentParticipants.map(p => p.participant)
    }

    // console.log('participant teams', participatingTeam)
    return participatingTeam.length > 0 ? participatingTeam : null
  }

  // check players
  if (tournament.participant_type_id === TournamentParticipantType.player) {
    // eslint-disable-next-line no-unused-vars
    let participatingPlayer = null
    if (tournament.enable_registration) {
      participatingPlayer = tournament.registrations.find(participant => participant.participant_id === me.id)
    } else {
      participatingPlayer = tournament.participants.find(participant => participant.participant_id === me.id)
    }

    // console.log('participant player', participatingPlayer)
    return participatingPlayer
  }

  return null
}

export const myTeamsAreParticipatingInTournament = (tournament => !!listMyTournamentParticipants(tournament))

export const enableInProgressButton = tournament => tournament?.status_id === TournamentStatusType.IN_PROGRESS

export const enableFinishedButton = tournament => (tournament?.status_id === TournamentStatusType.FINISHED || tournament?.status_id === TournamentStatusType.CLOSED)

export const enableShowAlreadyJoinedButton = tournament => {
  const me = store.getters['user/getMe']

  if (!me) {
    return false
  }

  if (tournament?.status_id === TournamentStatusType.IN_PROGRESS || tournament?.status_id === TournamentStatusType.FINISHED || tournament?.status_id === TournamentStatusType.CLOSED) {
    return false
  }

  // eslint-disable-next-line no-use-before-define
  if (!enableCheckinButton(tournament)) {
    return myTeamsAreParticipatingInTournament(tournament)
  }

  return false
}

export const enableCheckinButton = tournament => {
  const me = store.getters['user/getMe']

  if (!me) {
    return false
  }

  if (tournament?.status_id === TournamentStatusType.FINISHED || tournament?.status_id === TournamentStatusType.CLOSED) {
    return false
  }

  if (tournament?.enable_checkin === false || !tournament.checkin_start_at || !tournament.checkin_close_at) {
    return false
  }

  if (
    tournament?.status_id === TournamentStatusType.IN_PROGRESS && (tournament?.checkin_end_on_tournament_start === true)
  ) {
    return false
  }

  if (isBefore(new Date(tournament.checkin_close_at), new Date()) || isAfter(new Date(tournament.checkin_start_at), new Date())) {
    return false
  }

  return !!listMyTournamentParticipants(tournament, true)
}

export const alreadyCheckedIn = tournament => {
  if (!tournament) {
    return false
  }

  const me = store.getters['user/getMe']
  if (!me) {
    return false
  }

  const myTournamentParticipants = listMyTournamentParticipants(tournament)
  if (myTournamentParticipants && myTournamentParticipants.length === 0) {
    return false
  }

  const myParticipant = tournament.participants.find(participant => myTournamentParticipants.find(mp => mp.id === participant.participant_id))

  return myParticipant?.checked_in === true
}

export const enableJoinButton = tournament => {
  if (!tournament || !tournament.start_at) {
    // console.log('no tournament or start date')
    return false
  }

  if (tournament.tournament_type_id === TournamentType.PRIVATE) {
    return false
  }

  // hide if draft
  if (tournament?.status_id === TournamentStatusType.DRAFT) {
    // console.log('status draft')
    return false
  }

  // hide if finished
  if (tournament?.status_id === TournamentStatusType.FINISHED || tournament?.status_id === TournamentStatusType.CLOSED) {
    // console.log('status is finished')
    return false
  }

  // hide if in progress or finished
  if (tournament?.status_id === TournamentStatusType.IN_PROGRESS) {
    // console.log('status in progress')
    return false
  }

  // hide if participant number reached
  if ((tournament?.participants_number <= tournament?.participants.length)) {
    // console.log('participants full')
    return false
  }

  // if start time is in past, disable join button
  if (isBefore(new Date(tournament?.start_at), new Date())) {
    // console.log('start time in past')
    return false
  }

  // if registrations enabled, if date is not withing registration dates, disable join button
  if (
    tournament?.enable_registration === true && (
      isBefore(new Date(tournament?.registration_close_at), new Date())
      || isAfter(new Date(tournament?.registration_open_at), new Date())
    )
  ) {
    // console.log('registration date not within range')
    return false
  }

  const me = store.getters['user/getMe']
  if (!me) {
    // console.log('not logged in')
    return true
  }

  // if already participating, hide join button
  const myTournamentParticipants = listMyTournamentParticipants(tournament)
  if (myTournamentParticipants && myTournamentParticipants.length > 0) {
    return false
  }

  return true
}

export const showJoinTournamentCard = tournament => {
  if (tournament?.tournament_type_id === TournamentType.PRIVATE) {
    return false
  }

  if (tournament?.status_id === TournamentStatusType.DRAFT
    || tournament?.status_id === TournamentStatusType.IN_PROGRESS
    || tournament?.status_id === TournamentStatusType.FINISHED
    || tournament?.status_id === TournamentStatusType.CLOSED
  ) {
    // console.log('status')
    return false
  }

  if ((tournament?.participants_number <= tournament?.participants.length)) {
    // console.log('participant number')
    return false
  }

  // if passed tournament hide card
  if (isBefore(new Date(tournament?.start_at), new Date())) {
    // console.log('passed')
    return false
  }

  // validate registration dates
  if (
    tournament?.enable_registration === true && (
      isBefore(new Date(tournament?.registration_close_at), new Date())
      || isAfter(new Date(tournament?.registration_start_at), new Date())
    )
  ) {
    // console.log('registration date')
    return false
  }

  const me = store.getters['user/getMe']
  if (!me) {
    return false
  }

  // hide if participant team && not team leader
  if (tournament.participant_type_id === TournamentParticipantType.team) {
    const isLeaderOrOwner = me.teams.some(team => team.members.some(
      member => member.user_id === me.id && (
        member.role.id === MemberRoleType.TEAM_LEADER
        || member.role.id === MemberRoleType.OWNER
      ),
    ))
    if (!isLeaderOrOwner) {
      // console.log('not team leader | owner')
      return false
    }
  }

  // hide if already participating
  if (myTeamsAreParticipatingInTournament(tournament)) {
    // console.log('participating')
    return false
  }

  return true
}

export const canCancelRegistration = tournament => {
  const me = store.getters['user/getMe']
  if (!me) {
    return false
  }

  const myTournamentParticipants = listMyTournamentParticipants(tournament)

  // check among registrations
  if (tournament.enable_registration) {
    return tournament?.registrations.some(registration => myTournamentParticipants.find(myParticipant => myParticipant.id === registration.participant.id))
  }

  // check among participants
  return tournament.participants.some(
    participant => myTournamentParticipants.find(myParticipant => myParticipant.id === participant.participant.id),
  )
}

export const timeTillTournamentStart = tournament => {
  if (!tournament || !tournament.start_at || isAfter(new Date(), new Date(tournament.start_at))) {
    return ''
  }

  const startAt = utcToTimezone(
    tournament.start_at,
    tournament.timezone_id,
  )

  const {
    days,
    hours,
  } = intervalToDuration({
    start: startAt,
    end: new Date(),
  })

  const daysText = days > 0 ? `${days} days ` : ''
  const hoursText = hours > 0 ? `${hours} hours ` : ''

  return `${daysText} ${hoursText}`
}

export const tournamentStatusTitle = status => {
  switch (status) {
    case TournamentStatusType.IN_PROGRESS:
      return 'Tournament in progress'
    case TournamentStatusType.PUBLISHED:
      return 'Tournament ready to start'
    case TournamentStatusType.FINISHED:
    case TournamentStatusType.CLOSED:
    default:
      return 'Tournament finished'
  }
}

export const tournamentStatusColor = status => {
  switch (status) {
    case TournamentStatusType.IN_PROGRESS:
      return 'warning'
    case TournamentStatusType.PUBLISHED:
      return 'success'
    case TournamentStatusType.FINISHED:
    case TournamentStatusType.CLOSED:
    default:
      return 'secondary'
  }
}

export const filterMyMatches = async matches => {
  const me = await store.getters['user/getMe']
  if (!me) {
    return []
  }

  if (!matches) return []

  if (!matches?.some(match => match.opponent1 !== null || this.localMatch?.opponent2 !== null)) return []

  return matches?.filter(match => {
    if (match.opponent1?.participant_type === 'team') {
      return me.teams.some(team => match.opponent1?.participant_id === team.id || match.opponent2?.participant_id === team.id)
    }

    return match.opponent1?.participant_id === me.id || match.opponent2?.participant_id === me.id
  })
}
