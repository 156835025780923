const TournamentStatusType = Object.freeze({
  DRAFT: 1,
  PUBLISHED: 2,
  PREVIEW: 3,
  IN_PROGRESS: 4,
  FINISHED: 5,
  CLOSED: 6,
})

export default TournamentStatusType
